import React from "react";
import { Space, Layout, Button, Tabs, Collapse } from "antd";
import ReactPlayer from "react-player/youtube";

import { Divider, Col, Card, Row, Image } from "antd";
import { Typography } from "antd";
import {
  CheckCircleOutlined,
  BarChartOutlined,
  FilterOutlined,
  SafetyOutlined,
  AudioMutedOutlined,
  ControlOutlined
} from "@ant-design/icons";
const { Title } = Typography;
const { Header, Content, Footer } = Layout;
const { Meta } = Card;
import { Link } from "react-router-dom";
const { Panel } = Collapse;

const LandingPage = (props) => {
  return (
    <div className="site-card-wrapper">
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <div style={{ minHeight: "50px" }}> </div>
        <Row>
          <Col span={24}>
            <p style={{ fontSize: "20px" }}>
              Who are your most important customers? What are your most
              profitable products? Which region has the highest operating cost?
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={6} gutter={10}>
            <Title level={2}>Get answers with Eloquence</Title>
            <p style={{ fontSize: "20px" }}>
              <b>Eloquence</b> empowers you to make decisions based on data. No
              coding required.
            </p>
            {props.isLoggedIn ? (
              <Link to="/dashboard">
                <Button type="primary">Dashboard</Button>
              </Link>
            ) : (
              <Link to="/login">
                <Button type="primary">Try free</Button>
              </Link>
            )}
          </Col>
          <Col span={2} gutter={10}></Col>
          <Col span={16} gutter={10}>
            <Tabs
              defaultActiveKey="2"
              items={[
                {
                  label: `Live demo`,
                  key: "1",
                  children: (
                    <Card title="Live demo" bordered={true}>
                      Coming soon!
                    </Card>
                  )
                },
                {
                  label: `1 minute video`,
                  key: "2",
                  children: <ReactPlayer controls={true} url="https://youtu.be/jQG16RFGjJg" />
                }
              ]}
            />
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            {" "}
            <Title>
              Simple visual language that empowers you to ask questions
            </Title>
            <p style={{ fontSize: "20px" }}>
              Anyone can speak data fluently with Eloquence
            </p>
          </Col>
        </Row>
        <Divider />

        <Row gutter={16}>
          <Col span={8}>
            <Card hoverable>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <CheckCircleOutlined style={{ fontSize: 30 }} />
                <Meta
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="Quick and easy"
                  description="Connect to your database and ask questions in a structured natural language. Easy to use and precise."
                />
              </Space>
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <FilterOutlined style={{ fontSize: 30 }} />
                <Meta
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="Filter data to just what you need"
                  description="Easily filter based on your data's fields. No question is too complex."
                />
              </Space>
            </Card>{" "}
          </Col>
          <Col span={8}>
            <Card hoverable>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <BarChartOutlined style={{ fontSize: 30 }} />
                <Meta
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="Instant results"
                  description="No need to wait for the Data Science / IT department to create custom queries - Eloquence provide the answer right away."
                />
              </Space>
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Title>Security first</Title>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Card hoverable>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <SafetyOutlined style={{ fontSize: 30 }} />
                <Meta
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="Industry-strength encyption"
                  description="Data is encrypted at rest (twice!) and in transit using state of the art algorithms. No efforts were spared."
                />
              </Space>
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <ControlOutlined style={{ fontSize: 30 }} />
                <Meta
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="Online or on-premise"
                  description="User our online portal or contact us for the option to deploy within your company's firewall"
                />
              </Space>
            </Card>{" "}
          </Col>
          <Col span={8}>
            <Card hoverable>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <AudioMutedOutlined style={{ fontSize: 30 }} />
                <Meta
                  // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title="No data saved. Ever."
                  description="Your data is never saved, logged or otherwise recorded. We use SSL to both transfer datafrom the database to the server, and then to you."
                />
              </Space>
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Title>Frequently Asked Questions</Title>
          </Col>
        </Row>

        <Row gutter={16}>
          <Collapse defaultActiveKey={[ "1" ]} ghost>
            <Panel header="How do I create an account?" key="1">
              <p>
                Click "Try free", enter your email and a password and click
                "Login/Signup". It might take up to 5 seconds for the account to
                be created.
              </p>
            </Panel>
            <Panel header="What data sources do you support?" key="2">
              <p>
                Right now we only support PostgreSQL and MySQL. BigQuery and a
                few others coming soon!
              </p>
            </Panel>
            <Panel header="How do I connect to my data source?" key="3">
              <p>
                Click "Add datasource" then fill in the details and click
                "Create". The tool automatically imports your database schema
                and now knows (for relational databases) which tables are
                related through which foreign keys.
              </p>
            </Panel>
            <Panel header="Do you save any data?" key="4">
              <p>
                Absolutely no data is saved. It is sent through our server to
                your browser but the server does not persist it anywhere ever
                (not even in logs).
              </p>
            </Panel>
            <Panel header="How secure is Eloquence?" key="5">
              <p>
                We follow industry best practices - the data is encrypted at
                rest and in transit. The only thing we save on our server are
                the connection details to your database. They are encrypted when
                being transported over the Internet using SSL, encrypted in the
                database, and the database itself is saved on an encrypted disk.
              </p>
            </Panel>
          </Collapse>
        </Row>
      </Space>

      `    </div>
  );
};

export default LandingPage;
