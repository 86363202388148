import { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
import { Button, Select, Popover, Form, Input } from "antd";
import { Spinner } from "../spinner/Spinner.jsx";
import UserContext from "../../Contexts.js";
import { rootURI } from "../../utils/common_definitions.js";

const LoginPage = (props) => {
  let navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
  const { userObject, setUserObject } = useContext(UserContext);

  const onFinish = (values) => {
    // get and set the JWT token
    setLoading(true);

    fetch(rootURI + "/authenticate", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        // console.log("LOGIN RESULT");
        // console.log(result);
        // event.preventDefault();
        if (!result.error) {
          localStorage.setItem("user_id", result.auth_token.user_id);
          localStorage.setItem("user_token", result.auth_token.token);
          setUserObject({
            user_token: result.auth_token.token,
            user_id: result.auth_token.user_id,
          });
          navigate("/dashboard", {
            replace: true,
          });
        } else {
          alert(result.error.user_authentication);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };
  if (!loading) {
    return (
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          initialValue="hello@hello.com"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          initialValue="hello wonderful person"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Login/Signup
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    return <Spinner />;
  }
};

export default LoginPage;
