import SQL from 'sql-bricks';
import PostgresBricks from 'sql-bricks-postgres';



function convertRulesToSQL(rules, tableWithSchema) {
  if (!rules || !rules[ "rules" ]) {
    return [];
  }


  let conditions = [];

  let tableName = tableWithSchema.column;
  let schemaName = tableWithSchema.table;

  if (rules === null) {
    return conditions;
  }

  rules[ "rules" ].forEach(function (rule) {
    if (!rule[ "condition" ]) {
      return;
    }

    let fieldName = rule[ "field" ];

    if (fieldName.toLowerCase().includes("'s ")) {
      let splitFieldName = fieldName.split("'s ");
      tableName = splitFieldName[ 0 ];
      fieldName = splitFieldName[ 1 ];
    }

    let field = SQL.identifier([ schemaName, tableName, fieldName ]);

    switch (rule[ "operator" ]) {
      case "equal":
        conditions.push(SQL`${field} = ${rule[ "value" ]}`);
        break;
      case "notequal":
        conditions.push(SQL`NOT ${field} = ${rule[ "value" ]}`);
        break;
      case "lessthan":
        conditions.push(SQL`${field} < ${rule[ "value" ]}`);
        break;
      case "lessthanorequal":
        conditions.push(SQL`${field} <= ${rule[ "value" ]}`);
        break;
      case "greaterthan":
        conditions.push(SQL`${field} > ${rule[ "value" ]}`);
        break;
      case "greaterthanorequal":
        conditions.push(SQL`${field} >= ${rule[ "value" ]}`);
        break;
      case "ilike":
        conditions.push(SQL`${field} ILIKE ${`%${rule[ "value" ]}%`}`);
        break;
      case "notilike":
        conditions.push(SQL`NOT ${field} ILIKE ${`%${rule[ "value" ]}%`}`);
        break;
      case "in":
        conditions.push(SQL`${field} IN ${SQL.value(rule[ "value" ])}`);
        break;
      case "notin":
        conditions.push(SQL`NOT ${field} IN ${SQL.value(rule[ "value" ])}`);
        break;
      case "between":
        conditions.push(SQL`${field} BETWEEN ${rule[ "value" ][ 0 ]} AND ${rule[ "value" ][ 1 ]}`);
        break;
      case "notbetween":
        conditions.push(SQL`NOT ${field} BETWEEN ${rule[ "value" ][ 0 ]} AND ${rule[ "value" ][ 1 ]}`);
        break;
      case "contains":
        conditions.push(SQL`${field} ILIKE ${`%${rule[ "value" ]}%`}`);
        break;
      case "notcontains":
        conditions.push(SQL`NOT ${field} ILIKE ${`%${rule[ "value" ]}%`}`);
        break;
      case "startswith":
        conditions.push(SQL`${field} ILIKE ${`${rule[ "value" ]}%`}`);
        break;
      case "notstartswith":
        conditions.push(SQL`NOT ${field} ILIKE ${`${rule[ "value" ]}%`}`);
        break;
      case "endswith":
        conditions.push(SQL`${field} ILIKE ${`%${rule[ "value" ]}`}`);
        break;
      case "notendswith":
        conditions.push(SQL`NOT ${field} ILIKE ${`%${rule[ "value" ]}%`}`);
        break;
      case "dateequal":
        let dateequal = new Date(rule[ "value" ]);
        conditions.push(SQL`${field} = ${dateequal}`);
        break;
      case "datenotequal":
        let datenotequal = new Date(rule[ "value" ]);
        conditions.push(SQL`NOT ${field} = ${datenotequal}`);
        break;
      case "datelessthan":
        let datelessthan = new Date(rule[ "value" ]);
        conditions.push(SQL`${field} < ${datelessthan}`);
        break;
      case "datelessthanorequal":
        let datelessthanorequal = new Date(rule[ "value" ]);
        conditions.push(SQL`${field} <= ${datelessthanorequal}`);
        break;
      case "dategreaterthan":
        let dategreaterthan = new Date(rule[ "value" ]);
        conditions.push(SQL`${field} > ${dategreaterthan}`);
        break;
      case "dategreaterthanorequal":
        let dategreaterthanorequal = new Date(rule[ "value" ]);
        conditions.push(SQL`${field} >= ${dategreaterthanorequal}`);
        break;
      case "datebetween":
        let startDateBetween = new Date(rule[ "value" ][ 0 ]);
        let endDateBetween = new Date(rule[ "value" ][ 1 ]);
        conditions.push(SQL`${field} BETWEEN ${startDateBetween} AND ${endDateBetween}`);
        break;
      case "datenotbetween":
        let startDate = new Date(rule[ "value" ][ 0 ]);
        let endDate = new Date(rule[ "value" ][ 1 ]);
        conditions.push(SQL`NOT ${field} BETWEEN ${startDate} AND ${endDate}`);
        break;
      case "isnull":
        conditions.push(SQL`${field} IS NULL`);
        break;
      case "isnotnull":
        conditions.push(SQL`${field} IS NOT NULL`);
        break;
    }
  });

  return conditions;
}


function generateJoinChain(node) {
  var path = node[ 'path' ];

  var joinChain = [];

  path.forEach(function (currentPathElement) {
    var tableToJoinTo = currentPathElement[ 'source' ];
    var tableToJoin = currentPathElement[ 'target' ];
    var connectionInfo = currentPathElement[ 'connectionInfo' ];
    if (!connectionInfo) {
      return;
    }

    var partialJoin = {
      'tableToJoin': tableToJoin,
      'tableToJoinTo': tableToJoinTo,
      'connectionInfo': connectionInfo
    };

    joinChain.push(partialJoin);
  });

  return joinChain;
}


export function generateQuery(question, schema) {
  var nodes = question[ 'nodes' ];

  if (nodes.length === 0) {
    return SQL.select();
  }

  var calculatedQuery = {
    where: [],
    select: [],
    joins: [],
    group_by: [],
    aggregations: [],
    order_by: []
  };

  var query = SQL.select();
  var fromTable = null;

  nodes.forEach(function (node, index) {
    var operation = node[ 'operation' ];
    var table = node[ 'collection' ][ 'table' ];
    var namespace = node[ 'collection' ][ 'namespace' ];
    var selectedField = node[ 'selectedField' ];
    var filters = node[ 'booleanQuery' ];

    if (node.operation === '' || node.operation === null) {
      return;
    }

    namespace = namespace.trim() === '' ? 'public' : namespace;

    var tableWithSchema = PostgresBricks(`${namespace}.${table}`);

    if (index === 0) {
      fromTable = { table: table, namespace: namespace };
      query = SQL.select().from(tableWithSchema);
    }

    var conditions = convertRulesToSQL(filters, tableWithSchema, query);

    if (conditions.length > 0) {
      query.where(conditions);
    }

    if (index > 0) {
      var joinChain = generateJoinChain(node);

      joinChain.forEach(function (newJoinInfo) {
        var alreadyExists = calculatedQuery[ 'joins' ].some(function (existingJoinInfo) {
          var same_table =
            newJoinInfo[ 'tableToJoin' ] === existingJoinInfo[ 'tableToJoin' ] ||
            newJoinInfo[ 'tableToJoin' ] === fromTable;
          var same_conditions =
            newJoinInfo[ 'tableToJoinKey' ] === existingJoinInfo[ 'tableToJoinKey' ] &&
            newJoinInfo[ 'joinKeys' ] === existingJoinInfo[ 'joinKeys' ] &&
            newJoinInfo[ 'tableToJoinTo' ] === existingJoinInfo[ 'tableToJoinTo' ];
          return same_table && same_conditions;
        });

        if (!alreadyExists) {
          calculatedQuery[ 'joins' ].push(newJoinInfo);
        }
      });
    }

    let column_alias;
    let qualified_column;
    switch (operation) {
      case 'list of':
        // "list of" causes an aggregate function to require a group by
        // and it can only appear before any aggregates
        // if it is present we need to push all selected fields into "group by"
        let has_list_of = true;
        const disallowed_data_types = [
          // PostgreSQL data types
          'bytea', 'json', 'jsonb', 'xml', 'tsvector', 'tsquery', 'box', 'line',
          'lseg', 'path', 'polygon', 'circle', 'cidr', 'inet', 'bit', 'bit varying',
          'point', 'oid', 'array', 'hstore', 'uuid',

          // MySQL data types
          'blob', 'tinyblob', 'mediumblob', 'longblob', 'text', 'tinytext',
          'mediumtext', 'longtext', 'json', 'geometry', 'point', 'linestring',
          'polygon', 'multipoint', 'multilinestring', 'multipolygon', 'geometrycollection',

          // Snowflake data types
          'OBJECT', 'ARRAY', 'VARIANT', 'BINARY', 'VARBINARY', 'ANY', 'GEOGRAPHY'
        ];

        // Generate qualified column names for all selected columns
        // If none is selected generate them for each of the existing direct_fields of the table
        // Exclude blob types from the list of columns
        const visibleColumns = node[ 'visibleColumns' ];
        if (visibleColumns !== null && visibleColumns !== undefined) {
          visibleColumns.forEach(col => {
            if (disallowed_data_types.includes(schema[ 'namespaces' ][ namespace ][ 'tables' ][ table ][ 'direct_fields' ][ col ])) {
              return;
            }
            calculatedQuery.select.push(SQL.select(col).as(table + ' ' + col));
          });
        } else {
          const all_columns = schema[ 'namespaces' ][ namespace ][ 'tables' ][ table ][ 'direct_fields' ];
          all_columns.forEach(column_object => {
            if (disallowed_data_types.includes(Object.values(column_object)[ 0 ])) {
              return;
            }
            calculatedQuery.select.push(SQL.select(column_object[ Object.keys(column_object)[ 0 ] ]).as(table + ' ' + Object.keys(column_object)[ 0 ]));
          });
        }
        break;

      case 'number of':
        column_alias = `unique_${namespace}_${table}_${selectedField}`;
        qualified_column = `${namespace}.${table}.${selectedField}`;
        calculatedQuery.aggregations.push(`COUNT(DISTINCT ${qualified_column}) AS "${column_alias}"`);
        break;

      case 'sum of':
        column_alias = `total_${namespace}_${table}_${selectedField}`;
        qualified_column = `${namespace}.${table}.${selectedField}`;
        calculatedQuery.aggregations.push(`SUM(${qualified_column}) AS "${column_alias}"`);
        break;

      case 'average':
        column_alias = `average_${namespace}_${table}_${selectedField}`;
        qualified_column = `${namespace}.${table}.${selectedField}`;
        calculatedQuery.aggregations.push(`AVG(${qualified_column}) AS "${column_alias}"`);
        break;

      case 'for each':
        qualified_column = `${namespace}.${table}.${selectedField}`;
        calculatedQuery.group_by.push(qualified_column);
        break;

      default:
        console.log('Invalid operation in question node');
        break;
    }

    let selectFields = new Set(calculatedQuery.select);
    let aggregationFields = new Set(calculatedQuery.aggregations);
    let groupByFields = new Set(calculatedQuery.group_by);

    // Add group by fields to select fields only if they are not already included
    groupByFields.forEach(field => {
      selectFields.add(field);
    });

    // Combine select and aggregation fields
    let combinedSelectFields = Array.from(new Set([ ...selectFields, ...aggregationFields ]));

    // Construct the query
    if (combinedSelectFields.length > 0) {
      query = query.select(...combinedSelectFields);
    }

    // Reset tne aggregations for each node
    calculatedQuery.aggregations = [];

    // We can have multiple inbound or outbound (but not both) connections
    // between the same two tables.
    // Generate the join such that it includes all ourbound connections
    // This hash will be used to track the tables that have been joined
    // The key will be the full table name (with namespace) and the value will be the alias name used
    const joined_tables = {};
    const alias_count = {};

    calculatedQuery.joins.forEach(join_info => {
      const tableToJoin = join_info.tableToJoin;
      const tableToJoinTo = join_info.tableToJoinTo;
      const connectionInfo = join_info.connectionInfo;

      const full_table_to_join_name = `${tableToJoin.namespace}.${tableToJoin.table}`;
      const from_table_name = fromTable ? `${fromTable.namespace}.${fromTable.table}` : null;

      // Declare alias_name
      let alias_name;

      // If tableToJoin has not been joined already, use its original name
      if (!joined_tables[ full_table_to_join_name ] && (from_table_name && full_table_to_join_name !== from_table_name)) {
        alias_name = full_table_to_join_name;
      } else {
        // If tableToJoin is joining again, generate an alias
        alias_count[ full_table_to_join_name ] = (alias_count[ full_table_to_join_name ] || 0) + 1;
        alias_name = `${tableToJoin.table}_${connectionInfo[ 0 ].referenced_column}_${alias_count[ full_table_to_join_name ]}`;
      }
      joined_tables[ full_table_to_join_name ] = alias_name;

      // Gather all join conditions for this table
      const join_conditions = connectionInfo.reduce((acc, conn) => {
        const source_col = conn.source_column;
        const ref_col = conn.referenced_column;

        if (alias_name !== full_table_to_join_name) {
          acc[ `${alias_name}.${ref_col}` ] = `${tableToJoinTo.namespace}.${tableToJoinTo.table}.${source_col}`;
        } else {
          acc[ `${tableToJoin.namespace}.${tableToJoin.table}.${ref_col}` ] = `${tableToJoinTo.namespace}.${tableToJoinTo.table}.${source_col}`;
        }
        return acc;
      }, {});

      // Apply the JOIN
      const qualified_table_to_join = `${tableToJoin.namespace}.${tableToJoin.table}`;
      if (alias_name !== full_table_to_join_name) {
        query = query.leftJoin(qualified_table_to_join, join_conditions, { tableAlias: alias_name });
      } else {
        query = query.leftJoin(qualified_table_to_join, join_conditions);
      }
    });

    let groupByItems = calculatedQuery[ 'group_by' ];

    // Check if there are any group by items
    if (groupByItems && groupByItems.length > 0) {
      // Apply the group by clause using all group by items
      query = query.groupBy(...groupByItems);
    }

    calculatedQuery.group_by = [];

    calculatedQuery[ 'order_by' ].forEach(function (order_by_item) {
      query = query.orderBy(`${order_by_item} DESC`);
    });
  });

  console.log(query);
  return query;
}